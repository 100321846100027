var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"min-width":"175","open-on-hover":"","offset-y":"","bottom":"","left":"","close-delay":"100","close-on-click":"false","close-on-content-click":"false"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-n2",attrs:{"title":_vm.$t('user.menu.title'),"icon":""}},'v-btn',attrs,false),on),[(_vm.loggedIn)?_c('v-badge',{attrs:{"color":"accent","dot":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1):_c('v-icon',[_vm._v(" mdi-account-circle ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.loggedIn)?_c('UserAccount'):_vm._e(),(_vm.loggedIn)?_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("modal.collection.upload.title"))+" ")])]}}],null,false,3297281043),model:{value:(_vm.dialog.upload),callback:function ($$v) {_vm.$set(_vm.dialog, "upload", $$v)},expression:"dialog.upload"}},[_c('CollectionUpload',{model:{value:(_vm.dialog.upload),callback:function ($$v) {_vm.$set(_vm.dialog, "upload", $$v)},expression:"dialog.upload"}})],1),_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.collections.length)?_c('v-list-item',_vm._g({},on),[_c('v-badge',{attrs:{"content":_vm.collections.length,"color":"accent","inline":""}},[_vm._v(" "+_vm._s(_vm.$t("modal.collection.list.title"))+" ")])],1):_vm._e()]}}],null,false,391201110),model:{value:(_vm.dialog.list),callback:function ($$v) {_vm.$set(_vm.dialog, "list", $$v)},expression:"dialog.list"}},[_c('CollectionList',{model:{value:(_vm.dialog.list),callback:function ($$v) {_vm.$set(_vm.dialog, "list", $$v)},expression:"dialog.list"}})],1)],1)],1):_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("user.login.title"))+" ")])]}}]),model:{value:(_vm.dialog.login),callback:function ($$v) {_vm.$set(_vm.dialog, "login", $$v)},expression:"dialog.login"}},[_c('UserLogin',{model:{value:(_vm.dialog.login),callback:function ($$v) {_vm.$set(_vm.dialog, "login", $$v)},expression:"dialog.login"}})],1),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("user.register.title"))+" ")])]}}]),model:{value:(_vm.dialog.register),callback:function ($$v) {_vm.$set(_vm.dialog, "register", $$v)},expression:"dialog.register"}},[_c('UserRegister',{model:{value:(_vm.dialog.register),callback:function ($$v) {_vm.$set(_vm.dialog, "register", $$v)},expression:"dialog.register"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }